<template>
  <div>
    <div>
      <span class="d-block text-xl-h4 text-lg-h4 text-md-h4 text-sm-h5 text-h6 font-weight-bold black--text">
        Akun Dinas
      </span>
      <small class="text-subtitle-2">Halaman untuk menambah Akun Dinas</small>
    </div>
    <div
      class="mb-8"
    >
      <div
        class="my-8"
      >
        <v-row class="justify-space-between">
          <v-col
            md="4"
            cols="12"
          >
            <div>
              <v-text-field
                v-model="search"
                label="Cari"
                outlined
                dense
                class="white"
                hide-details="none"
                @change="searchHandler($event)"
              ></v-text-field>
            </div>
          </v-col>
          <v-col
            md="8"
          >
            <v-row class="justify-md-end justify-start">
              <v-col
                md="4"
                cols="12"
              >
                <div class="d-xl-flex d-lg-flex d-md-flex">
                  <!-- <span class="d-block mb-2 my-md-2 my-lg-2 my-xl-2 me-2">Kabupaten:</span> -->
                  <v-autocomplete
                    label="Kecamatan"
                    dense
                    :items="districts"
                    item-text="name"
                    item-value="uuid"
                    outlined
                    hide-details="none"
                    class="white"
                    clearable
                    @change="filterByDistrict($event)"
                  ></v-autocomplete>
                </div>
              </v-col>
              <v-col
                md="3"
                cols="12"
              >
                <div class="d-xl-flex d-lg-flex d-md-flex">
                  <!-- <span class="d-block mb-2 my-md-2 my-lg-2 my-xl-2 me-2">Jenjang:</span> -->
                  <v-autocomplete
                    label="Jenjang"
                    dense
                    :items="levels"
                    item-text="name"
                    item-value="uuid"
                    outlined
                    hide-details="none"
                    class="white"
                    clearable
                    @change="filterByLevel($event)"
                  ></v-autocomplete>
                </div>
              </v-col>
              <v-col
                md="3"
                cols="12"
              >
                <div class="d-xl-flex d-lg-flex d-md-flex">
                  <!-- <span class="d-block mb-2 my-md-2 my-lg-2 my-xl-2 me-2">Urutkan:</span> -->
                  <v-autocomplete
                    label="Urutkan"
                    dense
                    :items="sorts"
                    outlined
                    hide-details="none"
                    class="white"
                    clearable
                    @change="filterBySort($event)"
                  ></v-autocomplete>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <div v-if="!isLoadingCard">
        <v-row class="match-height">
          <v-col
            v-for="hob,index in hobs"
            :key="index"
            md="4"
          >
            <v-card min-height="300">
              <v-card-title
                class="pa-2 position-absolute"
                style="right: 0"
              >
                <v-spacer></v-spacer>
                <v-menu
                  offset-y
                  nudge-bottom="5"
                  nudge-left="60"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="black"
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="20">
                        {{ icons.mdiDotsVertical }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <a
                      @click.prevent="showFormEdit(hob.uuid)"
                    >
                      <v-list-item>
                        <v-list-item-title>
                          <div class="d-flex">
                            <div>
                              <v-img
                                width="16"
                                src="@/assets/icons/edit.svg"
                              ></v-img>
                            </div>

                            <small class="ml-2">Edit</small>
                          </div>
                        </v-list-item-title>
                      </v-list-item>
                    </a>
                    <a @click="confirmDestroy(hob.uuid)">
                      <v-list-item>
                        <v-list-item-title>
                          <div class="d-flex btn-delete">
                            <div>
                              <v-img
                                width="16"
                                src="@/assets/icons/trash-red.svg"
                              ></v-img>
                            </div>

                            <small class="ml-2">Hapus</small>
                          </div>
                        </v-list-item-title>
                      </v-list-item>
                    </a>
                  </v-list>
                </v-menu>
              </v-card-title>
              <v-card-text>
                <div class="mb-4">
                  <div class="d-flex justify-center ">
                    <div class="pt-8 mb-4">
                      <v-img
                        width="80"
                        height="90"
                        :src="hob.photo"
                        class="rounded-lg"
                      ></v-img>
                    </div>
                  </div>
                  <h3 class="font-weight-bold text-center">
                    {{ hob.name }}
                  </h3>
                </div>
                <div class="d-flex justify-center">
                  <div class="">
                    <p>NIP</p>
                    <p>NIK</p>
                    <p>Username</p>
                    <p>Telepon</p>
                  </div>
                  <div class="px-4">
                    <p>:</p>
                    <p>:</p>
                    <p>:</p>
                    <p>:</p>
                  </div>
                  <div class="">
                    <p>{{ hob.nip }}</p>
                    <p>{{ hob.nik }}</p>
                    <p>{{ hob.username }}</p>
                    <p>{{ hob.phone }}</p>
                  </div>
                </div>
                <v-divider></v-divider>
                <p class="font-weight-bold my-4">
                  Kecamatan
                </p>
                <v-chip
                  v-for="district,index in hob.district"
                  :key="index"
                  class="mb-2 me-2"
                >
                  <span class="text-truncate">
                    {{ district.name }}
                  </span>
                </v-chip>
                <p class="font-weight-bold my-4">
                  Jenjang
                </p>
                <v-chip
                  v-for="level,index in hob.level"
                  :key="index"
                  class="mb-2 me-2"
                >
                  <span class="text-truncate">
                    {{ level.name }}
                  </span>
                </v-chip>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-row class="mb-5">
          <v-col
            v-for="i, index in 6"
            :key="index"
            md="4"
            cols="12"
          >
            <v-skeleton-loader
              type="image"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </div>
      <div
        v-if="isEmpty"
        class="d-flex justify-center"
      >
        <div
          class="text-center"
        >
          <v-img
            src="@/assets/images/vector/empty.svg"
            class="mx-8 my-7"
          ></v-img>
          <p class="mt-7">
            Belum ada Akun Dinas
          </p>
        </div>
      </div>
    </div>
    <v-divider></v-divider>
    <div class="mt-6">
      <v-pagination
        v-model="page"
        :length="totalPages"
        total-visible="7"
        @change="paginationHandler"
      ></v-pagination>
    </div>
    <div class="d-flex justify-center mt-6">
      <v-btn
        color="primary"
        large
        @click="showFormAdd"
      >
        Tambah Akun Dinas
      </v-btn>
    </div>
    <ModalDialog
      :visible="modalDialog"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="!formValid || hob.photo === null"
      :dialog="dialog"
      :header="dialog === 'add' ? 'Tambah Akun Dinas': 'Edit Akun Dinas'"
      :text-button="dialog === 'add' ? 'Tambah': 'Update'"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template v-slot:body>
        <div>
          <v-text-field
            v-model="hob.name"
            label="Nama"
            outlined
            dense
          ></v-text-field>
        </div>
        <div class="mb-4">
          <FileInput
            v-if="dialog =='add'"
            :value="hob.photo"
            label="Photo"
            outlined
            dense
            :disabled="false"
            :prependicon="icons.mdiImageMultipleOutline"
            @input="getFile"
          >
          </FileInput>
          <FileInput
            v-if="dialog =='edit'"
            v-model="initialFile"
            label="Photo"
            outlined
            dense
            :disabled="false"
            :prependicon="icons.mdiImageMultipleOutline"
            @input="getFile"
          ></FileInput>
        </div>
        <div>
          <v-text-field
            v-model="hob.nip"
            label="NIP"
            type="number"
            outlined
            dense
          ></v-text-field>
        </div>
        <div>
          <v-text-field
            v-model="hob.nik"
            type="number"
            label="NIK"
            outlined
            dense
          ></v-text-field>
        </div>
        <div>
          <v-text-field
            v-model="hob.username"
            label="Username"
            outlined
            dense
            :rules="[validators.usernameValidator]"
          ></v-text-field>
        </div>
        <div v-if="dialog ==='add'">
          <v-text-field
            v-model="hob.password"
            label="Password"
            outlined
            dense
            type="password"
          ></v-text-field>
        </div>
        <div>
          <v-text-field
            v-model="hob.phone"
            label="Telepon"
            outlined
            dense
            type="number"
          ></v-text-field>
        </div>
        <div>
          <v-combobox
            v-model="hob.district_uuid"
            :items="districts"
            :loading="isLoadingData"
            item-text="name"
            item-value="uuid"
            label="Pilih Kecamatan"
            multiple
            outlined
            dense
            clearable
            small-chips
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    No results matching "<strong>
                    </strong>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-combobox>
        </div>
        <div>
          <v-combobox
            v-model="hob.level"
            :items="levels"
            label="Jenjang"
            item-text="name"
            item-value="uuid"
            multiple
            outlined
            dense
            clearable
            small-chips
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    No results matching "<strong>
                    </strong>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-combobox>
        </div>
      </template>
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Soal"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
    </ConfirmDialog>
  </div>
</template>

<script>
import { mdiDotsVertical, mdiImageMultipleOutline } from '@mdi/js'
import ConfirmDialog from '@/views/components/ConfirmDialog.vue'
import FileInput from '@/views/components/FileInput.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'
import { required, usernameValidator } from '@core/utils/validation'

export default {
  name: 'Hob',
  components: {
    ModalDialog,
    ConfirmDialog,
    FileInput,
  },
  data() {
    return {
      icons: {
        mdiDotsVertical,
        mdiImageMultipleOutline,
      },
      isLoadingData: false,
      isLoadingButton: false,
      isDisabledButton: false,
      isLoadingCard: true,
      isEmpty: false,
      page: 1,
      totalPages: 0,
      dialog: '',
      modalDialog: false,
      confirmDialog: false,
      search: '',
      hob: {
        nip: '',
        nik: '',
        name: '',
        username: '',
        password: '',
        level: [],
        photo: null,
        phone: '',
        district_uuid: [],
      },
      district_uuid: [],
      hobs: [],
      levels: [],
      level: '',
      service: 'hob',
      districts: [],
      regencies: [],
      regencyName: [],
      details: [],
      sort: '',
      sorts: ['Terbaru', 'Terlama'],
      province_uuid: '',
      formValid: false,
      validators: {
        required,
        usernameValidator,
      },
    }
  },
  computed: {
    initialFile: {
      get() {
        if (typeof this.hob.photo === 'string') {
          return new File([this.hob.photo], this.hob.photo, { type: 'text/plain' })
        }

        return this.hob.photo
      },
      set() {
        return this.hob.photo
      },
    },
  },
  watch: {
    hob: {
      handler() {
        const valid = []
        const requiredField = ['name', 'nip', 'nik', 'username', 'password', 'phone', 'district_uuid']

        Object.entries(this.hob).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)
      },
      deep: true,
    },
    page: {
      handler() {
        this.isLoadingCard = true
        this.list({ page: this.page })
      },
    },
    level: {
      handler() {
        this.isLoadingCard = true
        this.listHeadbranch()
      },
    },
    sort: {
      handler() {
        this.isLoadingCard = true
        this.listHeadbranch()
      },
    },
    district_uuid: {
      handler() {
        this.isLoadingCard = true
        this.listHeadbranch()
      },
    },
    search: {
      handler() {
        if (this.search.length > 3 || this.search.length === 0) {
          this.isLoadingCard = true
          if (this.timeout) clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.list({ search: this.search })
          }, 500)
        }
      },
    },
  },
  beforeMount() {
    this.getUuid()
    this.listDistrict()
    this.listLevel()
  },
  mounted() {
    this.listHeadbranch()
  },
  methods: {
    resetForm() {
      this.hob.photo = null
      this.hob.name = ''
      this.hob.username = ''
      this.hob.password = ''
      this.hob.nik = ''
      this.hob.nip = ''
      this.hob.phone = ''
      this.hob.district_uuid = []
      this.hob.level = []
    },

    getFile(data) {
      this.hob.photo = data
    },
    getUuid() {
      this.details = JSON.parse(localStorage.getItem('user')).user.details
      this.details.forEach(el => {
        if (el.regencies !== null) {
          this.regency_uuid = el.regencies.uuid
          console.log(this.regency_uuid)
        } else {
          this.province_uuid = el.province.uuid
          console.log(this.province_uuid)
        }
      })
    },
    async listDistrict() {
      await this.$services.ApiServices.list('district', { regencies_uuid: this.regency_uuid, per_page: 'all' }).then(
        ({ data }) => {
          this.districts = data.data
        },
        err => console.error(err),
      )
    },
    async listLevel() {
      await this.$services.ApiServices.list('level').then(
        ({ data }) => {
          this.levels = data.data.slice(3, 5)
        },
        err => console.error(err),
      )
    },
    async listHeadbranch(params = {}) {
      if (!this.regency_uuid) {
        await this.$services.ApiServices.list(this.service, {
          ...params,
          province_uuid: this.province_uuid,
          per_page: '6',
          level_uuid: this.level,
          sort: this.sort,
        }).then(({ data }) => {
          this.hobs = data.data
          this.isLoadingCard = false
          this.isEmpty = false
          if (this.hobs.length < 1) {
            this.isEmpty = true
          }
          this.totalPages = data.meta.last_page
        })
      } else {
        await this.$services.ApiServices.list(this.service, {
          ...params,
          regency_uuid: this.regency_uuid,
          district_uuid: this.district_uuid,
          per_page: '6',
          level_uuid: this.level,
          sort: this.sort,
        }).then(({ data }) => {
          this.hobs = data.data
          this.isLoadingCard = false
          this.isEmpty = false
          if (this.hobs.length < 1) {
            this.isEmpty = true
          }
          this.totalPages = data.meta.last_page
        })
      }
    },
    showFormAdd() {
      this.resetForm()
      this.dialog = 'add'
      this.modalDialog = true
    },
    async add() {
      this.isLoadingButton = true
      this.formData = new FormData()
      this.formData.append('nip', this.hob.nip)
      this.formData.append('nik', this.hob.nik)
      this.formData.append('name', this.hob.name)
      this.formData.append('username', this.hob.username)
      this.formData.append('password', this.hob.password)
      this.formData.append('phone', this.hob.phone)
      this.formData.append('photo', this.hob.photo)

      if (this.hob.photo === null || this.hob.photo === '') {
        this.formData.delete('photo')
      }
      this.hob.district_uuid.forEach(item => {
        this.formData.append('district_uuid[]', item.uuid)
      })
      this.hob.level.forEach(item => {
        this.formData.append('level_uuid[]', item.uuid)
      })

      await this.$services.ApiServices.add(this.service, this.formData).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
        },
        err => {
          console.error(err)
          this.isLoadingButton = false
        },
      )
      await this.listHeadbranch()
      this.isLoadingButton = false
      this.modalDialog = false
      this.resetForm()
    },
    async showFormEdit(uuid) {
      this.dialog = 'edit'
      this.modalDialog = true
      await this.$services.ApiServices.get(this.service, uuid).then(
        ({ data }) => {
          this.hob = { ...data.data, district_uuid: data.data.district, level: data.data.level }
        },
        err => {
          console.error(err)
        },
      )
    },
    async update() {
      this.dialog = 'edit'
      this.modalDialog = true
      this.isLoadingButton = true
      this.formData = new FormData()
      this.formData.append('nip', this.hob.nip)
      this.formData.append('nik', this.hob.nik)
      this.formData.append('name', this.hob.name)
      this.formData.append('username', this.hob.username)
      this.formData.append('password', this.hob.password)
      this.formData.append('phone', this.hob.phone)
      this.formData.append('photo', this.hob.photo)
      this.hob.district_uuid.forEach(item => {
        this.formData.append('district_uuid[]', item.uuid)
      })
      this.hob.level.forEach(item => {
        this.formData.append('level_uuid[]', item.uuid)
      })
      if (this.hob.photo === null || typeof this.hob.photo === 'string') {
        this.formData.delete('photo')
      }

      await this.$services.ApiServices.update(this.service, this.formData, this.hob.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
        },
        err => {
          console.error(err)
        },
      )
      await this.listHeadbranch()
      this.isLoadingButton = false
      this.modalDialog = false
      this.resetForm()
    },
    confirmDestroy(uuid) {
      this.confirmDialog = true
      this.hob.uuid = uuid
    },
    handlerButton() {
      if (this.dialog === 'add') this.add()
      else this.update()
    },
    async destroy() {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy(this.service, this.hob.uuid).then(({ data }) => {
        this.showSnackbar({
          text: data.result.message,
          color: 'success',
        })
      })
      await this.listHeadbranch()
      this.confirmDialog = false
      this.isLoadingButton = false
      this.resetForm()
    },
    filterBySort(data) {
      if (data === 'Terbaru') {
        this.sort = 'desc'
      } else if (data === 'Terlama') {
        this.sort = 'asc'
      } else if (data === 'Semua') {
        this.sort = ''
      }
    },
    filterByDistrict(uuid) {
      this.district_uuid = uuid ?? ''
    },
    filterByLevel(level) {
      this.level = level ?? ''
    },
    searchHandler(data) {
      this.search = data
    },
    async paginationHandler(pageNumber) {
      this.page = pageNumber
    },

    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style>
.btn-delete {
  color: #e30000 !important;
}
</style>
